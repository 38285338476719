<template>
    <v-layout pa-2 class="question" mt-5 wrap>
   
        <v-container>
            <v-flex xs-1>
                <v-select v-model="select" label="Selecteer een opleidingsrichting"
                    :items="opleidingen.filter(f => f.parent == null)" :menu-props="{ maxHeight: '400' }"
                    item-text="naam" item-value="id" @change="openDialog"
                    :reset-on-options-change='true'></v-select></v-flex>

            <v-flex v-if="selected.length > 0">
                <v-data-table :disable-sort="true" flat hide-default-footer group-by="richting" :headers="headers"
                    :items="selectedList" class="elevation-1">
                    <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                        <th colspan="6">
                            <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                            </v-icon>
                            {{ items[0].richting }}
                        </th>
                    </template>
                    <template v-slot:[`item.opties`]="{ item }">
                        <v-menu dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="primary" text dark v-bind="attrs" v-on="on">
                                    mdi-menu
                                </v-icon>
                            </template>

                            <v-list>
                                <v-list-item :key="index" @click="openOptionsDialog(i, item)"
                                    v-for="(i, index) in ['aanpassen', 'verwijderen']">
                                    <v-list-item-title>{{ i }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex v-else>
                <div pa-8 class="text-center">Hier kunt u relevante WOZ opleidingen toevoegen. Selecteer een
                    opleidingsrichting en
                    voeg vervolgens de opleiding toe.</div>
            </v-flex>
        </v-container>

        <v-dialog v-model="dialog" max-width="500px">

            <v-card v-if="opleidingen.find(f => f.id == moduleModel.parent)" color="white">
                <v-card-title><span class="headline">{{ opleidingen.find(f => f.id == moduleModel.parent).naam
                        }}</span></v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="" v-model="valid">
                        <v-select :disabled="isEdit" :rules="[rules.required]" autofocus
                            :items="opleidingen.filter((f) => f.parent == moduleModel.parent)" item-text="naam"
                            label="Module" item-value="id" required v-model="moduleModel.id"></v-select>
                        <v-select :rules="[rules.required]" :items="['nee', 'ja']" label="Behaald?" required
                            v-model="moduleModel.behaald"></v-select>
                        <v-select :rules="[rules.required]"
                            v-if="moduleModel.behaald == 'ja' && opleidingen.find(f => f.id == moduleModel.id).hasLVLB"
                            :items="['nee', 'ja']" label="Geregistreerd in LVLB register?" required
                            v-model="moduleModel.geregistreerdLVLB"></v-select>
                        <v-select :rules="[rules.required]"
                            v-if="moduleModel.behaald == 'ja' && opleidingen.find(f => f.id == moduleModel.id).hasNRVT"
                            :items="['nee', 'ja']" label="Geregistreerd in NRVT register?" required
                            v-model="moduleModel.geregistreerdNRVT"></v-select>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">Annuleren</v-btn>
                    <v-btn :disabled="!valid" color="blue darken-1" @click="toevoegen">
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </v-layout>
</template>

<script>

import client from "api-client";


export default {
    props: { vraag: Object, value: { type: Array, default: () => [] } },
    data: () => ({
        opleidingen: [],
        selected: [],
        headers: [
            { text: "Richting", value: "richting" },
            { text: "Module", value: "module" },
            { text: "Behaald", value: "behaald" },
            { text: "Geregistreerd LVLB", value: "geregistreerdLVLB" },
            { text: "Geregistreerd NRVT", value: "geregistreerdNRVT" },
            { text: "", value: "opties", sortable: false }
        ],
        rules: {
            required: (v) => !!v || "Waarde is verplicht"
        },
        dialog: false,
        isEdit: false,
        selectedRichting: null,
        moduleModel: {},
        valid: false,
        select: null
    }),
    methods: {
        openDialog() {

            this.isEdit = false;
            const richting = { ...this.opleidingen.find((f) => f.id == this.select) }
            this.moduleModel = { parent: richting.id }
            this.dialog = true
            this.select = {}
        },
        submitChages() {
            this.$emit("answerChanged", this.selected);
        },
        getOpleidingen() {
            client.fetchOpleidingen().then((data) => {
                this.opleidingen = data
            })
        },
        toevoegen() {

            const found = this.selected.findIndex(i => i.id === this.moduleModel.id);

            var newModel = { ...this.moduleModel }
            if (newModel.behaald == 'nee') {
                newModel.geregistreerdLVLB = null
                newModel.geregistreerdNRVT = null
            }

            if (found !== -1)
                this.selected.splice(found, 1, newModel)
            else
                this.selected.push(newModel)

            this.submitChages();
            this.dialog = false
        },
        openOptionsDialog(i, item) {
            this.moduleModel = {}
            if (i == 'aanpassen') {
                this.isEdit = true
                this.moduleModel = { ...item }
                this.dialog = true
            } else if (i == 'verwijderen') {
                const found = this.selected.findIndex(f => f.id === item.id);
                if (found !== -1) {
                    this.selected.splice(found, 1)
                    this.submitChages();
                }
            }
        }

    },
    computed: {
        selectedList() {
            return this.selected.map((v) => ({
                richting: this.opleidingen.find(f => f.id == v.parent)?.naam,
                module: this.opleidingen.find(f => f.id == v.id)?.naam,
                ...v,
            }));
        },
    },
    watch: {
        value: {
            immediate: true,
            handler: function (c) {
                if (c) {
                    this.selected = [...c];
                }
            },
        },

    },
    created() {
        this.getOpleidingen()
    }
}
</script>